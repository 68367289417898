<script setup lang="ts">
import BaseProgressBar from "@/components/BaseProgressBar.vue";
import { computed, inject, onMounted, ref } from "vue";
import { copyToClipboard } from "@/utils/сopyToClipboard";

const data = inject("data");

const loadingLimit = 90;

const propress = ref(1);

const startProgress = (start = 1) => {
  propress.value = Math.ceil((start / loadingLimit) * 100);

  if (start === loadingLimit) {
    return;
  }

  setTimeout(() => {
    startProgress(start + 1);
  }, 1000);
};

const copy = () => {
  copyToClipboard();
};

onMounted(() => {
  startProgress();
});

const isWaiting = computed(() => {
  return propress.value > 30;
});
</script>

<template>
  <div class="embede-progress-bar" :class="{ waiting: isWaiting }">
    <p class="hint">Пожалуйста, подождите</p>

    <base-progress-bar hide-text :progress="propress" />

    <button class="copy" @click.stop.prevent="copy">
      Если нет времени ждать — нажмите сюда, чтобы скопировать
      ссылку и вернуться чуть позднее
    </button>
  </div>
</template>

<style scoped>
.hint {
  font-family: Consolation Mono C;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.copy {
  all: unset;
  cursor: pointer;
  font-family: Consolation Mono C;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  color: #fff;
  text-decoration: underline;
  margin-top: 40px;

  position: absolute;
  bottom: 21px;
  max-width: 80%;
  @media (min-width: 780px) {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    bottom: 42px;
  }
}

.embede-progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar {
  box-shadow: none;
  background: transparent;
  border: none;
  width: 90px;
  height: 90px;
  margin-top: 10px;
}

.text {
  font-family: Maki-Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
