<script setup lang="ts"></script>

<template>
  <svg
    width="25"
    height="28"
    viewBox="0 0 25 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.375 14.1653L0.884816 27.7274L0.884817 0.603246L24.375 14.1653Z"
      fill="#E990FF"
    />
  </svg>
</template>

<style scoped></style>
