<script setup lang="ts">
import { computed, withDefaults } from "vue";

const props = withDefaults(defineProps<{ borderWidth: number }>(), {
  borderWidth: 5,
});

const width = computed(() => {
  return `${props.borderWidth}px`;
});
</script>

<template>
  <span class="loader"></span>
</template>

<style scoped>
.loader {
  width: 72px;
  height: 72px;
  border: v-bind(width) solid #ff8833;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
