<script setup lang="ts">
import SelectScreenTextBg from "@/components/svg/SelectScreenTextBg.vue";
import HoverRing from "@/components/svg/HoverRing.vue";
import { inject, onMounted, ref } from "vue";
import { reachGoal } from "@/utils/reachGoal";
const data = inject("data");
const emit = defineEmits<{
  (e: "select", value: 60 | 70 | 80): void;
}>();

const onSelect = (data: 60 | 70 | 80) => {
  reachGoal(`${data}`);
  emit("select", data);
};
const container = ref<HTMLDivElement>();

const scrollToTop = () => {
  if (!container.value) {
    return;
  }

  const c = container.value?.scrollWidth / 100;

  if (container.value?.scrollLeft + c < container.value?.scrollWidth / 3) {
    container.value.scrollTo(container.value?.scrollLeft + c, 0);
    window.requestAnimationFrame(scrollToTop);
  }
};
scrollToTop();

const item = ref<HTMLDivElement>();

onMounted(() => {
  if (!item.value) {
    return;
  }
  console.log(container.value?.scrollLeft, container.value?.scrollWidth);
  setTimeout(() => {
    scrollToTop();
  }, 300);
});
</script>

<template>
  <div class="select-screen">
    <div>
      <SelectScreenTextBg class="select-screen__bg" />
      <p class="select-screen__text">Выберите стиль эпохи</p>
    </div>

    <div ref="container" class="select-screen__buttons">
      <button class="select-screen__button" @click="onSelect(60)">
        <img :src="data['60']" class="icon" />
        <img :src="data['hover-ring']" class="select-screen__hover one" />
      </button>

      <button class="select-screen__button" @click="onSelect(70)" ref="item">
        <img :src="data['70']" class="icon" />
        <img :src="data['hover-ring']" class="select-screen__hover two" />
      </button>

      <button class="select-screen__button" @click="onSelect(80)">
        <img :src="data['80']" class="icon" />
        <img :src="data['hover-ring']" class="select-screen__hover three" />
      </button>
    </div>
  </div>
</template>

<style scoped>
.select-screen {
  height: 100dvh;
  padding: 128px 0 0;
  box-sizing: border-box;
  overflow: hidden;
}

.select-screen__text {
  position: relative;
  z-index: 11;
  margin-bottom: 32px;
  text-align: center;
  color: rgba(255, 136, 51, 1);
  font-family: Consolation Mono C;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.select-screen__buttons {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  grid-gap: 10%;
  z-index: 11;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-padding-inline: 40px;
  padding: 0 15% 0 10%;
}

.select-screen__buttons::-webkit-scrollbar {
  display: none;
}

.select-screen__bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
}

.select-screen__button {
  all: unset;
  cursor: pointer;
  scroll-snap-align: start;
  position: relative;
}

.select-screen__button .icon {
  position: relative;
  z-index: 1;
}

.select-screen__hover {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.select-screen__hover.one {
  top: 5px;
  left: 5px;
}

.select-screen__hover.two {
  top: 9px;
  left: 5px;
}

.select-screen__hover.three {
  top: 6px;
  left: -38px;
}

.select-screen__button:hover .select-screen__hover {
  display: block;
}

@media (min-width: 1020px) {
  .select-screen {
    padding-top: 162px;
    max-width: 1100px;
    margin: 0 auto;
  }

  .select-screen__text {
    font-family: Consolation Mono C;
    font-size: 33px;
    font-weight: 400;
    line-height: 46.2px;
    text-align: center;
  }

  .select-screen__bg {
    top: 110px;
  }

  .select-screen__buttons {
    padding: 0;
    grid-gap: 0;
  }
}
</style>
