const data: Record<string, string> = {
  "60": "/60.png",
  "people-mobile": "/people-mobile.png",
  woman: "/woman.png",
  "70": "/70.png",
  "tv-mask": "/tv-mask.png",
  man: "/man.png",
  plus: "/plus.svg",
  logo: "/logo.svg",
  "main-hero-desktop": "/main-hero-desktop.png",
  "80": "/80.png",
  "main-hero-mobile": "/main-hero-mobile.png",
  "hover-ring": "/hover-ring.png",
};

const mainAssets: Record<string, string> = {
  stripes: "/stripes.svg",
  desktop: "/desktop-bg.png",
};
export default class DataStorage {
  loadedAssets: Record<string, string> = {};
  mainAssets: Record<string, string> = {};

  destroy() {
    Object.values(this.loadedAssets).forEach((url) => URL.revokeObjectURL(url));
    this.loadedAssets = {};
  }

  loadMainAssets() {
    const promises = Object.keys(mainAssets).map((el) =>
      fetch(mainAssets[el])
        .then((resp) => resp.blob())
        .then((asset) => {
          this.mainAssets[el] = URL.createObjectURL(asset);
        })
        .catch(ignoreAbort)
    );

    return Promise.all(promises);
  }
  load(callback: (progress: number, isFinished: boolean) => void) {
    let completedCount = 0;

    const totalAssets = Object.keys(data).length + 2;

    const onProgress = () => {
      completedCount++;

      const progress = (completedCount / totalAssets) * 100;
      callback(progress, totalAssets === completedCount);
    };

    const font2 = new Promise((resolve) => {
      const font = new FontFace(
        "Rostelecom Basis",
        "url(/font/RostelecomBasis-Regular.otf)"
      );

      font.load().then((loaded_face) => {
        onProgress();
        document.fonts.add(loaded_face);
        resolve(true);
      });
    });
    const font3 = new Promise((resolve) => {
      const font = new FontFace(
        "Consolation Mono C",
        "url(/font/consolation-mono-c.ttf)"
      );

      font.load().then((loaded_face) => {
        onProgress();
        document.fonts.add(loaded_face);
        resolve(true);
      });
    });
    const promises = Object.keys(data).map((el) =>
      fetch(data[el])
        .then((resp) => resp.blob())
        .then((asset) => {
          this.loadedAssets[el] = URL.createObjectURL(asset);

          onProgress();
        })
        .catch(ignoreAbort)
    );

    return Promise.all([...promises, font2, font3]);
  }
}

function ignoreAbort(error: any) {
  if (
    error !== "unload" &&
    error.type !== "abort" &&
    error.name !== "AbortError"
  )
    throw error;
}
