const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export async function copyToClipboard() {
  const url = window.location.href;

  try {
    if (isMobile) {
      await navigator.share({ url });
    } else {
      await navigator.clipboard.writeText(url);
      alert("Успешно скопировано в буфер");
    }
  } catch (e) {
    await navigator.clipboard.writeText(url);
    alert("Успешно скопировано в буфер");
  }
}
