<template>
  <div class="root">
    <div class="root__background"></div>

    <transition
      name="fade"
      @before-enter="onBeforeEnter"
      @after-leave="onAfterLeave"
    >
      <main-loader v-if="appState === AppState.LOADING" :progress="progress">
      </main-loader>

      <start-screen
        v-else-if="appState === AppState.START"
        @select-gender="onGenderSelect"
      />

      <select-screen
        v-else-if="appState === AppState.CHOOSE_EPOCH"
        @select="onEpochSelected"
      />

      <photo-screen
        v-else-if="appState === AppState.PHOTO"
        :selected-epoch="selectedEpoch"
        :selected-gender="selectedGender"
        :default-uuid="defaultUuid"
        @show-result="onShowResult"
      />

      <result-screen
        v-bind="resultData"
        @repeat="resetData"
        v-else-if="appState === AppState.RESULT"
      />
    </transition>

    <div class="root__stripes" />

    <img src="@/assets/logo.svg" alt="logo" class="root__logo" />

    <epoch-progress
      :state="appState"
      v-if="appState !== AppState.LOADING && appState !== AppState.START"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, provide, ref } from "vue";
import DataStorage from "@/utils/data-storage";
import MainLoader from "@/components/MainLoader.vue";
import StartScreen from "@/components/StartScreen.vue";
import SelectScreen from "@/components/SelectScreen.vue";
import EpochProgress from "@/components/EpochProgress.vue";
import PhotoScreen from "@/components/PhotoScreen.vue";
import ResultScreen from "@/components/ResultScreen.vue";
import { AppState } from "@/types/AppState";

const currentIndex = ref(0);

const selectedGender = ref<"male" | "female">("male");
const selectedEpoch = ref<60 | 70 | 80>(60);

const onGenderSelect = (data: "male" | "female") => {
  selectedGender.value = data;
  appState.value = AppState.CHOOSE_EPOCH;
};

const onEpochSelected = (data: 60 | 70 | 80) => {
  selectedEpoch.value = data;
  appState.value = AppState.PHOTO;
};

const resultData = ref<{
  title: string;
  result_text: string;
  result_url: string;
  uuid: string;
}>({
  title: "",
  result_text: "",
  result_url: "",
  uuid: "",
});

const onShowResult = (data: {
  title: string;
  result_text: string;
  result_url: string;
  uuid: string;
}) => {
  resultData.value = data;
  appState.value = AppState.RESULT;
};

const defaultUuid = ref("");

const appState = ref<AppState>(AppState.LOADING);

if (window.location.pathname.includes("result")) {
  const els = window.location.pathname.split("/");
  currentIndex.value = 1;
  defaultUuid.value = els[els.length - 1];
}

const newDataStorage = new DataStorage();
const isLoading = ref(true);
const data = ref({});
const mainAssets = ref({});
const progress = ref(0);

newDataStorage.loadMainAssets().then(() => {
  mainAssets.value = newDataStorage.mainAssets;
  newDataStorage.load((p, isFinished) => {
    progress.value = p;

    if (isFinished) {
      isLoading.value = false;
      data.value = newDataStorage.loadedAssets;

      appState.value = defaultUuid.value ? AppState.PHOTO : AppState.START;
    }
  });
});

provide("data", data);

const onBeforeEnter = () => {
  document.body.style.overflow = "hidden";
};
const onAfterLeave = () => {
  document.body.style.overflow = "auto";
};

const desktopImg = computed(() => {
  return `url(${mainAssets.value.desktop})`;
});

const stripes = computed(() => {
  return `url(${mainAssets.value.stripes})`;
});

const resetData = () => {
  defaultUuid.value = "";
  appState.value = AppState.START;
  resultData.value = {
    title: "",
    result_url: "",
    result_text: "",
    uuid: "",
  };
};
</script>

<style>
.fade-enter-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

body,
html,
#app {
  height: 100%;
}

.root {
  position: absolute;
  width: 100vw;
  min-height: 100%;
  overflow: hidden;
}

.root__logo {
  position: absolute;
  left: 18px;
  top: 18px;
  width: 212px;
  height: 20px;
  z-index: 10;

  @media (min-width: 768px) {
    width: 325px;
    height: 32px;
    top: 21px;
    right: 30px;
  }
}

.root__background {
  background-image: v-bind(desktopImg);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
}
.root__stripes {
  background-image: v-bind(stripes);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.25;
  z-index: 9;
}
</style>
