<script setup lang="ts">
import LeftArrow from "@/components/svg/LeftArrow.vue";
import { inject } from "vue";
import { reachGoal } from "@/utils/reachGoal";
const data = inject("data");
const emit = defineEmits<{
  (e: "select-gender", data: "male" | "female"): void;
}>();

const onSelect = (data: "male" | "female") => {
  reachGoal(data === "male" ? "Im a hero" : "im a heroessa");
  emit("select-gender", data);
};
</script>

<template>
  <div class="first-screen">
    <div class="first-screen__container">
      <picture class="first-screen__img">
        <source
          media="(min-width: 780px)"
          :srcset="data['main-hero-desktop']"
        />
        <img :src="data['main-hero-mobile']" alt="Главный герой" />
      </picture>

      <p class="first-screen__text">
        Празднуем День молодежи вместе! <br />
        Загрузи свою фотографию и узнай, <br />
        как бы ты выглядел в стиле 60-х, 70-х <br />
        или 80-х. Находи своих звездных <br />
        двойников и делись с друзьями!
      </p>

      <div class="first-screen__buttons">
        <button class="first-screen__button-man" @click="onSelect('male')">
          <LeftArrow class="first-screen__button-man-left" />
          <span class="first-screen__button-man-right">Я — герой</span>
        </button>

        <button class="first-screen__button-woman" @click="onSelect('female')">
          <span class="first-screen__button-woman-left">Я — героиня</span>
          <LeftArrow class="first-screen__button-woman-right" />
        </button>
      </div>
      <img :src="data['people-mobile']" class="first-screen__people" />

      <img :src="data.man" class="first-screen__man" />
      <img :src="data.woman" class="first-screen__woman" />
      <div></div>
    </div>
  </div>
</template>

<style scoped>
.first-screen {
  display: flex;
  flex-direction: column;
  margin: 0 25px;
  padding: 65px 0 30px;
  justify-content: space-between;
  height: 100dvh;
  box-sizing: border-box;
}

.first-screen__img {
  position: relative;
  margin-bottom: 8px;
  z-index: 1;
}

.first-screen__img img {
  width: 100%;
  height: auto;
}

.first-screen__man,
.first-screen__woman {
  display: none;
}
.first-screen__text {
  position: relative;
  z-index: 11;
  font-family: Consolation Mono C;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.first-screen__buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  z-index: 11;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.first-screen__people {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
}

.first-screen__button-man {
  display: flex;
  box-sizing: border-box;
  background: transparent;
  outline: none;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.first-screen__button-man-left {
  background: rgba(57, 0, 101, 0.8);

  border-style: solid;

  border-color: rgba(217, 63, 255, 1);
  padding: 19px 24px 19px 24px;
  gap: 28px;
  border-radius: 0px 0px 0px 30px;
  border-width: 3px 0px 3px 3px;
}

.first-screen__button-man-right {
  background: rgba(57, 0, 101, 0.8);
  border-style: solid;
  padding: 19px 40px 19px 40px;
  gap: 28px;
  border-radius: 0px 30px 0px 0px;
  border-width: 3px;
  border-color: rgba(217, 63, 255, 1);
  color: #fff;
  text-transform: uppercase;
  font-family: Rostelecom Basis;
  font-size: 18px;
  line-height: 15px;
  font-weight: 500;
  text-align: center;
}

.first-screen__button-man:hover .first-screen__button-man-left,
.first-screen__button-man:hover .first-screen__button-man-right {
  box-shadow: inset 1px 1px 8px 0px rgba(217, 63, 255, 1);
  border-style: solid;
}

.first-screen__button-man:active .first-screen__button-man-left,
.first-screen__button-man:active .first-screen__button-man-right {
  border-style: solid;
  background: rgba(119, 0, 212, 0.8);
}

.first-screen__button-woman {
  display: flex;
  box-sizing: border-box;
  background: transparent;
  outline: none;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.first-screen__button-woman-left {
  background: rgba(43, 36, 0, 0.8);

  border-style: solid;

  border-color: rgba(255, 236, 63, 1);
  padding: 19px 30px 19px 30px;
  gap: 28px;
  color: #fff;
  border-width: 3px 0px 3px 3px;
  border-radius: 0px 0px 0px 30px;

  font-family: Rostelecom Basis;
  font-size: 18px;
  line-height: 15px;

  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: 660px) {
    border-radius: 30px 0px 0px 0;
  }
}

.first-screen__button-woman-right {
  transform: rotate(180deg);
  background: rgba(43, 36, 0, 0.8);
  border-style: solid;
  padding: 19px 24px 19px 24px;

  gap: 28px;

  border-radius: 0 0 0 30px;

  border-width: 3px;
  border-color: rgba(255, 236, 63, 1);
  color: #fff;

  @media (min-width: 660px) {
    border-radius: 30px 0px 0px 0px;
  }
}

.first-screen__button-woman:hover .first-screen__button-woman-left,
.first-screen__button-woman:hover .first-screen__button-woman-right {
  border-style: solid;
  box-shadow: inset 1px 1px 8px 0px rgba(255, 236, 63, 1);
}

.first-screen__button-woman:active .first-screen__button-woman-left,
.first-screen__button-woman:active .first-screen__button-woman-right {
  border-style: solid;
  background: rgba(151, 127, 0, 0.8);
}

@media (min-width: 660px) {
  .first-screen__people {
    display: none;
  }

  .first-screen__woman,
  .first-screen__man {
    position: absolute;
    bottom: 0;
    display: block;
    z-index: 1;
  }

  .first-screen__man {
    left: -10%;
    height: 90%;
    max-height: 600px;
  }

  .first-screen__woman {
    right: -10%;
    height: 80%;
    max-height: 500px;
  }

  .first-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 25px 0;
    padding-top: 105px;
    padding-bottom: 0;
    justify-content: space-between;
    height: 100dvh;
    box-sizing: border-box;
    max-width: 900px;
  }

  .first-screen__container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .first-screen__text {
    font-family: Consolation Mono C;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    max-width: 350px;
  }

  .first-screen__buttons {
    flex-direction: row;
    gap: 25px;
    margin-top: 50px;
    position: static;
    transform: none;
    width: auto;
  }
}

@media (min-width: 950px) {
  .first-screen {
    margin: 0 auto;
  }
}
</style>
