<script setup lang="ts">
import SelectScreenTextBg from "@/components/svg/SelectScreenTextBg.vue";
import {
  computed,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import CircleLoader from "@/components/CircleLoader.vue";
import { getFilmId } from "@/utils/get-film-id";
import EmbededPogressBar from "@/components/EmbededPogressBar.vue";

const status = ref<"idle" | "loading" | "waiting-deepfake" | "error">("idle");

const title = computed(() => {
  return status.value === "waiting-deepfake"
    ? "Готовим гримёров..."
    : "Загрузите ваше фото";
});

const data = inject("data");
const props = defineProps<{
  defaultUuid?: string;
  selectedGender: "female" | "male";
  selectedEpoch: 60 | 70 | 80;
}>();

const emit = defineEmits<{
  (
    e: "show-result",
    data: {
      title: string;
      result_text: string;
      result_url: string;
      uuid: string;
    }
  ): void;
}>();

const preventDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const imageUrl = ref("");
const error = ref("");
const errorEl = ref<HTMLDivElement | null>(null);

const isInViewport = (element: HTMLDivElement) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
const scrollToError = () => {
  if (!errorEl.value) {
    return;
  }

  if (!isInViewport(errorEl.value)) {
    errorEl.value.scrollIntoView({ behavior: "smooth" });
  }
};

const fetchedUuid = ref("");

const sendDeepFakeRequest = async (id: string) => {
  const res = await fetch(`https://api.youth-day.ru/api/deepfakes/${id}`);
  return await res.json();
};

const getFilmById = async (id: string) => {
  const res = await fetch(`https://api.youth-day.ru/api/films/${id}`);
  return await res.json();
};

let currentInterval: ReturnType<typeof setTimeout> | null = null;
const findDeepFake = async (id: string) => {
  try {
    const { result_url, film_id } = await sendDeepFakeRequest(id);

    if (result_url) {
      const { title, result_text } = await getFilmById(film_id);

      emit("show-result", { result_url, result_text, title, uuid: id });
    } else {
      currentInterval = setTimeout(() => findDeepFake(id), 5000);
    }
  } catch (e) {
    console.log(e);
  }
};

const postDeepFake = async () => {
  status.value = "waiting-deepfake";

  let url = "https://api.youth-day.ru/api/deepfakes";

  const film_id = getFilmId(props.selectedGender, props.selectedEpoch);
  const data = JSON.stringify({ film_id, input_url: imageUrl.value });

  try {
    const res = await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!res.ok) {
      return;
    }
    const { id } = await res.json();
    window.history.pushState({}, "", `/result/${id}`);

    fetchedUuid.value = id;
    findDeepFake(id);
  } catch (err) {
    console.error(err);
  }
};

const uploadFile = async (file: File) => {
  error.value = "";

  let url = "https://api.youth-day.ru/api/image/upload";
  let formData = new FormData();
  status.value = "loading";

  formData.append("file", file);

  try {
    const res = await fetch(url, {
      method: "POST",
      body: formData,
    });
    if (!res.ok) {
      status.value = "error";
      const errorText = await res.text();

      if (errorText.includes("Input image doesn't contain")) {
        error.value = "Слишком много лиц на фотографии";
      } else if (errorText === "Invalid input image") {
        error.value = "Лицо на фотографии не найдено";
      } else {
        error.value = "Попробуйте загрузить другое изображение";
      }

      nextTick(() => {
        scrollToError();
      });

      return;
    }

    error.value = "";
    const { image_url } = await res.json();
    imageUrl.value = image_url;
    postDeepFake();
  } catch (e) {
    error.value = "Попробуйте загрузить другое изображение";
    status.value = "error";
  }
};

const handleDrop = (e: DragEvent) => {
  let dt = e.dataTransfer;

  if (!dt) {
    return;
  }

  let files = dt.files;

  uploadFile(files[0]);
};

onMounted(() => {
  let dropArea = document.getElementById("drop-area");

  if (!dropArea) {
    return;
  }
  const highlight = (e: DragEvent) => {
    if (!dropArea) {
      return;
    }
    dropArea.classList.add("highlight");
  };

  const unhighlight = (e: DragEvent) => {
    if (!dropArea) {
      return;
    }
    dropArea.classList.remove("highlight");
  };

  ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
    if (!dropArea) {
      return;
    }
    dropArea.addEventListener(eventName, preventDefaults, false);
  });
  dropArea.addEventListener("drop", handleDrop, false);
  ["dragenter", "dragover"].forEach((eventName) => {
    if (!dropArea) {
      return;
    }
    dropArea.addEventListener(eventName, highlight, false);
  });
  ["dragleave", "drop"].forEach((eventName) => {
    if (!dropArea) {
      return;
    }
    dropArea.addEventListener(eventName, unhighlight, false);
  });
});

const onChange = (e) => {
  uploadFile(e.target.files[0]);
};

status.value = props.defaultUuid ? "waiting-deepfake" : "idle";

if (props.defaultUuid) {
  findDeepFake(props.defaultUuid);
}
const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

let isFocused = true;
window.onblur = () => {
  isFocused = false;

  if (isMobile) {
    return;
  }

  if (currentInterval) {
    clearInterval(currentInterval);
    currentInterval = null;
  }
};

window.onfocus = () => {
  if (isMobile || isFocused) {
    return;
  }

  isFocused = true;

  if (currentInterval) {
    clearInterval(currentInterval);
    currentInterval = null;
  }

  findDeepFake(fetchedUuid.value);
};

onBeforeUnmount(() => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.onfocus = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.onblur = () => {};
});
</script>

<template>
  <div class="photo-screen">
    <div>
      <SelectScreenTextBg class="photo-screen__bg" />
      <p class="photo-screen__text">{{ title }}</p>
    </div>

    <div
      id="drop-area"
      :class="{
        loading: status === 'loading' || status === 'waiting-deepfake',
      }"
    >
      <form class="my-form" @change="onChange">
        <input
          type="file"
          id="fileElem"
          accept="image/*"
          :disabled="status === 'loading' || status === 'waiting-deepfake'"
        />

        <label for="fileElem">
          <template v-if="status === 'idle' || status === 'error'">
            <span class="photo-screen__hint">
              На фото должно быть хорошо видно ваше лицо
            </span>
            <span class="photo-screen__hint">
              Принимаются фогографии до 5 mb в формате .jpg или .png
            </span>
            <span class="photo-screen__hint">
              Кликните по этому полю или перетащите сюда фотографию
            </span>
          </template>
          <CircleLoader v-else-if="status === 'loading'"></CircleLoader>
          <EmbededPogressBar v-else-if="status === 'waiting-deepfake'" />
        </label>
      </form>
    </div>
    <div ref="errorEl" class="error-message" v-if="error">
      {{ error }}
    </div>
    <img :src="data['man']" class="photo-screen__man" />
    <img :src="data['woman']" class="photo-screen__woman" />
  </div>
</template>

<style scoped>
.error-message {
  padding: 32px;
  border-radius: 28px;
  border: 3px dashed rgba(255, 136, 51, 1);
  font-family: Consolation Mono C;
  font-size: 10.43px;
  font-weight: 400;
  line-height: 12.51px;
  text-align: center;
  color: #fff;
  margin: 0 40px;
  background-color: rgba(255, 136, 51, 0.2);
  background-image: url("@/assets/sign.svg");
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 780px) {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 50px;
    font-family: Consolation Mono C;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    min-width: 400px;
    border-radius: 40px;
  }
}

.photo-screen {
  height: 100dvh;
  padding: 128px 0 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media (min-width: 780px) {
    padding-top: 162px;
  }
}

.photo-screen__bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
  z-index: 11;

  @media (min-width: 780px) {
    top: 110px;
  }
}

.photo-screen__man,
.photo-screen__woman {
  display: none;
  position: absolute;
  bottom: 0;
  z-index: 1;

  @media (min-width: 780px) {
    display: block;
  }
}

.photo-screen__woman {
  left: 5%;
  height: 80%;
  max-height: 500px;
}

.photo-screen__man {
  right: 0;
  height: 80%;
  max-height: 600px;
}

.photo-screen__hint {
  font-family: Consolation Mono C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #fff;
  margin-bottom: 17px;
  display: block;

  @media (min-width: 780px) {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
}

.photo-screen__hint:last-child {
  margin-bottom: 0;
}

.photo-screen__text {
  position: relative;
  z-index: 11;
  margin-bottom: 54px;
  text-align: center;
  color: rgba(255, 136, 51, 1);
  font-family: Consolation Mono C;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;

  @media (min-width: 780px) {
    font-family: Consolation Mono C;
    font-size: 33px;
    font-weight: 400;
    line-height: 46.2px;
    text-align: center;
  }
}
.photo-loader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.error {
  position: absolute;
  top: 120%;
  border: 3px solid #6f0c0c;
  box-shadow: 5px 4px 4px 0px #00000040;
  border-radius: 10px;
  background: #f0f0f0;
  font-family: Maki-Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;

  @media (min-width: 800px) {
    font-size: 20px;
    border-radius: 20px;
    padding: 20px;
  }
}

#drop-area {
  background-image: url("@/assets/plus.svg");
  background-color: rgba(255, 136, 51, 0.1);

  border: 4px dashed rgba(255, 136, 51, 1);
  border-radius: 40px;
  font-family: sans-serif;
  padding: 36px 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 11;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 22px 12px;
  box-sizing: border-box;
  min-height: 250px;

  @media (min-width: 780px) {
    padding: 50px 60px;
    width: 650px;
    height: 350px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

#drop-area.loading {
  background-image: none;
}

#fileElem {
  display: none;
}

.photo-loader--success #drop-area {
  background: linear-gradient(
    0deg,
    rgba(50, 191, 132, 0.05),
    rgba(50, 191, 132, 0.05)
  );
  border: 2px dashed #32bf84;
}

.photo-loader--success .text {
  color: #32bf84;
}

@media (min-width: 600px) {
  .header-icon {
    width: 12%;
    margin-bottom: 12px;
  }

  .header-title {
    margin-bottom: 12px;
  }
}

@media (min-width: 950px) {
  .header-icon {
    width: 57px;
    height: 57px;
  }
  .text {
    display: block;
    font-family: Rostelecom Basis;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
  }
}
</style>
