<script setup lang="ts">
import PlayIcon from "@/components/svg/PlayIcon.vue";
import { computed, inject, onMounted, ref } from "vue";
import DownloadIcon from "@/components/svg/DownloadIcon.vue";
import ShareIcon from "@/components/svg/ShareIcon.vue";
import RepeatIcon from "@/components/svg/RepeatIcon.vue";
import { copyToClipboard } from "@/utils/сopyToClipboard";
import { reachGoal } from "@/utils/reachGoal";
import CircleLoader from "@/components/CircleLoader.vue";
const player = ref<HTMLVideoElement | null>(null);
const isPlay = ref(false);
const data = inject("data");

const emit = defineEmits<{
  (e: "repeat"): void;
}>();
const props = defineProps<{
  title: string;
  result_text: string;
  result_url: string;
  uuid: string;
}>();

function playPauseVideo() {
  if (!player.value) {
    return;
  }

  isPlay.value = !isPlay.value;
  player.value.play();

  if (isPlay.value) {
    player.value.play();
  } else {
    player.value.pause();
  }
}

onMounted(() => {
  if (player.value) {
    player.value.addEventListener("ended", () => {
      isPlay.value = false;
    });
  }
});

const onRepeat = () => {
  reachGoal("repeat");
  emit("repeat");
};

const copy = async () => {
  reachGoal("share");
  copyToClipboard();
};

const isVideoLoading = ref(false);

const downloadVideo = () => {
  reachGoal("download");
  isVideoLoading.value = true;
  const urlToFetch = props.result_url
    .replace(
      "s3.storage.selcloud.ru/rtk-youth/",
      "b2ac8446-6bf2-42ef-8213-8daefaf3a82b.selstorage.ru/"
    )
    .split("?")[0];
  fetch(urlToFetch)
    .then((resp) => {
      return resp.blob();
    })
    .then((asset) => {
      const blobUrl = URL.createObjectURL(asset);
      const link = document.createElement("a");

      link.href = blobUrl;
      // Set the download attribute with a filename
      link.download = `${props.uuid}.mp4`;
      // Append the link to the body
      document.body.appendChild(link);
      // Simulate a click on the link
      link.click();
      // Remove the link after downloading
      document.body.removeChild(link);
      // Release the blob URL
      window.URL.revokeObjectURL(blobUrl);
      isVideoLoading.value = false;
    })
    .catch((err) => {
      console.log(err);
      isVideoLoading.value = false;
    });
};

const validUrl = computed(() => {
  console.log(props.result_url);

  return (
    props.result_url
      .replace(
        "s3.storage.selcloud.ru/rtk-youth/",
        "b2ac8446-6bf2-42ef-8213-8daefaf3a82b.selstorage.ru/"
      )
      .split("?")[0] + "#t=1"
  );
});
</script>

<template>
  <div class="result-screen">
    <div class="result-screen__container">
      <picture class="result-screen__img">
        <source
          media="(min-width: 780px)"
          :srcset="data['main-hero-desktop']"
        />
        <img :src="data['main-hero-mobile']" alt="Главный герой" />
      </picture>

      <div class="result-screen__body">
        <div class="result-screen__video">
          <video ref="player" playsinline>
            <source :src="validUrl" />
          </video>

          <img class="result-screen__mask" :src="data['tv-mask']" />
          <div class="hoverable-overlay" @click="playPauseVideo"></div>
          <button
            v-if="!isPlay"
            id="btnPlayPause"
            class="play-pause play"
            title="play"
            accesskey="P"
            @click.stop="playPauseVideo"
          >
            <PlayIcon />
          </button>
        </div>

        <div class="result-screen__content">
          <p class="result-screen__title">
            {{ title }}
          </p>

          <p class="result-screen__subtitle">
            {{ result_text }}
          </p>

          <div class="result-screen__buttons">
            <button class="result-screen__button-man" @click="onRepeat">
              <span class="result-screen__button-man-left">
                <RepeatIcon />
              </span>

              <span class="result-screen__button-man-right">повторить</span>
            </button>

            <button
              class="result-screen__button result-screen__download"
              :disabled="isVideoLoading"
              @click="downloadVideo"
            >
              <CircleLoader
                v-if="isVideoLoading"
                :border-width="1"
                style="width: 24px; height: 24px"
              />

              <DownloadIcon v-else />
            </button>

            <button
              class="result-screen__button result-screen__download"
              @click="copy"
            >
              <ShareIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.result-screen__button-man {
  display: flex;
  box-sizing: border-box;
  background: transparent;
  outline: none;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.result-screen__button-man-left {
  background: rgba(57, 0, 101, 0.8);

  border-style: solid;

  border-color: rgba(217, 63, 255, 1);
  gap: 28px;
  border-radius: 0px 0px 0px 30px;
  border-width: 3px 0px 3px 3px;
  display: none;
  height: 48px;
  flex-shrink: 0;
  box-sizing: content-box;

  @media (min-width: 780px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
}

.result-screen__button-man-right {
  background: rgba(57, 0, 101, 0.8);
  border-style: solid;
  padding: 16px 37px 16px 37px;
  height: 54px;
  box-sizing: border-box;
  gap: 28px;
  border-radius: 0px 30px 0px 30px;
  border-width: 3px;
  border-color: rgba(217, 63, 255, 1);
  color: #fff;
  text-transform: uppercase;
  font-family: Rostelecom Basis;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
  @media (min-width: 780px) {
    border-radius: 0px 30px 0px 0px;
  }
}

.result-screen__button-man:hover .result-screen__button-man-left,
.result-screen__button-man:hover .result-screen__button-man-right {
  box-shadow: inset 1px 1px 8px 0px rgba(217, 63, 255, 1);
  border-style: solid;
}

.result-screen__button-man:active .result-screen__button-man-left,
.result-screen__button-man:active .result-screen__button-man-right {
  border-style: solid;
  background: rgba(119, 0, 212, 0.8);
}

.play-pause {
  all: unset;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  border: 2px solid rgba(233, 144, 255, 1);
  background: rgba(57, 0, 101, 0.8);
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  //border-radius:
}

.play-pause.play svg {
  position: relative;
  left: 3px;
}

.play-pause.pause {
  display: none;
}

.play-pause.pause:hover {
  display: flex;
}

.result-screen__buttons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  position: relative;
  z-index: 11;
}

.result-screen__button {
  all: unset;
  cursor: pointer;
  border: 2.3px solid rgba(217, 63, 255, 1);
  background: rgba(57, 0, 101, 0.8);
  box-sizing: border-box;
}

.result-screen__download {
  border-radius: 500px;
  padding: 13px;
  display: flex;
  width: 54px;
  height: 54px;
  justify-content: center;
  align-items: center;
}

.result-screen__download:hover {
  box-shadow: inset 1px 1px 8px 0px rgba(217, 63, 255, 1);
}

.result-screen__again {
  border-radius: 0px 23.86px 0px 23.86px;
  padding: 18px 40px 18px 40px;
  color: #fff;
  font-family: Rostelecom Basis;
  font-size: 14.31px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
}

.result-screen__img {
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 780px) {
    max-width: 570px;
    margin-bottom: 30px;
  }
}

.result-screen__img img {
  @media (min-width: 780px) {
    width: 100%;
  }
}

.result-screen {
  padding: 64px 20px 90px;
  box-sizing: border-box;
  position: relative;

  @media (min-width: 780px) {
    padding: 76px 0 0;
  }
}

.result-screen__container {
  @media (min-width: 950px) {
    max-width: 900px;
    margin: 0 auto;
  }
}
.result-screen__video {
  position: relative;
  margin: 0 auto 45px;
  @media (min-width: 780px) {
    margin: 0 60px 0 0;
    flex: 1 0 450px;
  }
}

.result-screen__video video {
  width: 100%;
  position: relative;
  top: 12px;
  @media (min-width: 780px) {
  }
}

.result-screen__mask {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 103%;
  height: 115%;
  @media (min-width: 780px) {
    height: 115%;
  }
}
.hoverable-overlay {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 103%;
  height: 115%;
  z-index: 10;
  cursor: pointer;
  @media (min-width: 780px) {
    height: 115%;
  }
}

.hoverable-overlay:hover + .play-pause.pause {
  display: flex;
}
.result-screen__title {
  font-family: Consolation Mono C;
  font-size: 22px;
  font-weight: 400;
  line-height: 28.6px;
  text-align: left;
  color: #ff8833;
  margin-bottom: 20px;

  @media (min-width: 780px) {
    font-family: Consolation Mono C;
    font-size: 30px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    margin-bottom: 30px;
  }
}

.result-screen__subtitle {
  font-family: Consolation Mono C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;

  @media (min-width: 780px) {
    font-family: Consolation Mono C;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-bottom: 30px;
  }
}

.result-screen__body {
  @media (min-width: 780px) {
    display: flex;
    align-items: flex-start;
  }
}
</style>

<style scoped lang="sass">
@function vw($target)
  $vw-context: (1440*.01) * 1px
  @return (calc($target/$vw-context)) * 1vw


@media (min-width: 1440px)
  .result-screen__button-man
    height: vw(54px)

  .result-screen__button-man-left
    gap: vw(28px)
    border-radius: 0px 0px 0px vw(30px)
    padding: vw(14px) vw(20px)
    height: min-content
    svg
      width: vw(24px)
      height: vw(28px)

  .result-screen__button-man-right
    padding: vw(16px) vw(37px) vw(16px) vw(37px)
    height: min-content
    gap: vw(28px)
    font-size: vw(18px)
    line-height: vw(24px)
    border-radius: 0px vw(30px) 0px 0px


  .play-pause
    border: 2px solid rgba(233, 144, 255, 1)
    width: vw(74px)
    height: vw(74px)


  .play-pause.play svg
    left: vw(3px)

  .result-screen__buttons
    gap: vw(10px)

  .result-screen__download
    border-radius: 500px
    padding: vw(13px)
    width: vw(54px)
    height: vw(54px)

  .result-screen__again
    border-radius: 0px vw(24px) 0px vw(24px)
    padding: vw(18px) vw(40px) vw(18px) vw(40px)
    font-size: vw(14px)
    line-height: vw(14px)


  .result-screen__img
    margin: 0 auto vw(20px)
    max-width: vw(570px)
    margin-bottom: vw(30px)

  .result-screen__img img
    width: 100%

  .result-screen
    padding: vw(76px) 0 0


  .result-screen__container
    max-width: vw(900px)


  .result-screen__video
    margin: 0 vw(60px) 0 0
    flex: 1 0 vw(450px)



  .result-screen__video video
    top: vw(12px)

  .result-screen__mask
    left: vw(-5px)
    top: vw(-5px)
    width: 103%
    height: 115%

    @media (min-width: 780px)
      height: 115%

  .hoverable-overlay
    left: vw(-5px)
    top: vw(-5px)
    width: 103%
    height: 115%

    @media (min-width: 780px)
      height: 115%


  .result-screen__title
    font-size: vw(30px)
    line-height: vw(42px)
    margin-bottom: vw(30px)

  .result-screen__subtitle
    font-size: vw(15px)
    line-height: vw(21px)
    margin-bottom: vw(30px)
</style>
