const data = {
  male: {
    60: [4],
    70: [9],
    80: [1, 10, 11],
  },
  female: {
    60: [5],
    70: [7],
    80: [2, 3, 6],
  },
};
export const getFilmId = (gender: "male" | "female", epoch: 60 | 70 | 80) => {
  const elements = data[gender][epoch];
  return elements[Math.floor(Math.random() * elements.length)];
};
